import { createContext, useContext, useMemo, useState } from "react";

type TransitionControlContextType = {
  transitionDuration: number;
  isTransitionPaused: boolean;
  setIsTransitionPaused: (paused: boolean | ((current: boolean) => boolean)) => void;
};

type TransitionControlProviderProps = React.PropsWithChildren<{
  transitionDuration: number;
  initialTransitionState?: boolean;
}>;

const TransitionControlContext = createContext<TransitionControlContextType | undefined>(undefined);

export const TransitionControlProvider: React.FC<TransitionControlProviderProps> = ({
  children,
  transitionDuration,
  initialTransitionState,
}) => {
  const [isTransitionPaused, setIsTransitionPaused] = useState(initialTransitionState || false);

  const contextValue = useMemo(
    () => ({
      transitionDuration,
      isTransitionPaused,
      setIsTransitionPaused,
    }),
    [transitionDuration, isTransitionPaused]
  );

  return (
    <TransitionControlContext.Provider value={contextValue}>
      {children}
    </TransitionControlContext.Provider>
  );
};

export const useTransitionControl = (): TransitionControlContextType | undefined =>
  useContext(TransitionControlContext);
