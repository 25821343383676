import { useFloating, autoUpdate, offset, Placement, size } from "@floating-ui/react";
import cn from "classnames";
import { PressEvent } from "react-aria";
import ReactDOM from "react-dom";
import {
  withGroupedChildren,
  type GroupedChildrenProps,
  type WithGroupedChildrenComponent,
} from "react-grouped-children";

import styles from "./Tooltip.module.scss";

const childrenSpec = {
  Anchor: null,
  Overlay: null,
} as const;

export type TooltipProps = {
  /**
   * Current state of tooltip.
   */
  open?: boolean;

  /**
   * Initial state of tooltip.
   */
  defaultOpen?: boolean;

  /**
   * Callback for when tooltip trigger is clicked.
   */
  onPress?: (e: PressEvent) => void;

  /**
   * Callback for when tooltip trigger is focused.
   */
  onFocus?: () => void;

  /**
   *  Handler that is called when the overlay's open state changes.
   */
  onOpenChange?: (isOpen: boolean) => void;

  /**
   * The tooltip position relative to its trigger.
   */
  placement: Placement;

  /**
   * Popup dynamically adjust height/width when overflow.
   */
  isPopupFixed?: boolean;

  /**
   * The additional offset applied along the main axis between the element and its anchor element.
   */
  offset?: number;

  /**
   * The additional offset applied along the cross axis between the element and its anchor element.
   */
  crossOffset?: number;

  /*
   * Additional class names to apply to the tooltip container.
   */
  className?: string;
};

export type TooltipPlacementProps = {
  placement: Placement;
  offset?: number;
  crossOffset?: number;
};

/**
 * `Tooltip` includes a trigger and a tooltip (popup) when the trigger is clicked/focused.
 * It is recommended to provides anchor as a Button component from `react-aria-components`
 * for native support. Passing other element interactive elements may cause the tooltip to render incorrectly for uncontrolled tooltip.
 * Visit Tooltip composition page for more information on how to use.
 */
const TooltipContainer: React.FC<
  React.PropsWithChildren & GroupedChildrenProps<typeof childrenSpec> & TooltipProps
> = ({
  anchor,
  overlay,
  defaultOpen,
  open,
  onOpenChange,
  placement,
  offset: mainOffset,
  crossOffset,
  isPopupFixed = false,
  className,
}) => {
  const { refs, floatingStyles } = useFloating({
    placement,
    open: open || defaultOpen,
    onOpenChange,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({ mainAxis: mainOffset, crossAxis: crossOffset }),
      !isPopupFixed &&
        size({
          apply({ availableWidth, availableHeight, elements }) {
            Object.assign(elements.floating.style, {
              maxWidth: `${availableWidth}px`,
              maxHeight: `${availableHeight}px`,
            });
          },
        }),
    ],
  });

  return (
    <>
      <div className={cn(className, styles.tooltipTrigger)} ref={refs.setReference}>
        {anchor}
      </div>
      {(open || defaultOpen) &&
        ReactDOM.createPortal(
          <div className={className} ref={refs.setFloating} style={floatingStyles}>
            {overlay}
          </div>,
          document.body
        )}
    </>
  );
};

const Tooltip: WithGroupedChildrenComponent<
  typeof childrenSpec,
  React.PropsWithChildren & TooltipProps
> = withGroupedChildren({ childrenSpec })(TooltipContainer);

export { Tooltip };
