import { BreakpointContext } from "../../../context/BreakpointContext";
import { routeString, RouteSection } from "Helpers/routes";
import { UserEncoreStatus, UserEncoreTiers } from "Helpers/users";
import { useThunkDispatch } from "Hooks/thunk";
import { toggleBenefitsModal } from "ReduxSlices/general";
import { encoreTierSelector, dashboardBannerStatesSelector } from "ReduxSlices/user";
import { LoadStatus } from "Types/loadStatus";
import { EncorePackagePaymentStatus, VerifiedVehicle } from "Types/vehicle";
import { differenceInMonths, differenceInDays, parse, format } from "date-fns";
import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const EXPIRING_DURATION = 3;
const DAYS_TO_HIDE_BANNER = 7;
interface BannerStates {
    bannerState: {
        status: UserEncoreStatus;
        packageExpiryDate?: string;
        packagePurchasePaylink?: string | null;
        hideCta?: boolean;
        disableCta?: boolean;
        showBanner: boolean;
    };
    extraBannerState?: { status: UserEncoreStatus };
}
interface BannerTokensProps {
    [key: string]: {
        headerToken?: { [key: string]: string };
        token?: { [key: string]: string | number };
    };
}

export const useOnClick = (membershipStatus: UserEncoreStatus) => {
    const dispatch = useThunkDispatch();
    const isMobile = useContext(BreakpointContext);
    const navigate = useNavigate();

    return useMemo(() => {
        const readMore = () => {
            isMobile
                ? navigate(routeString(RouteSection.PlatinumBenefitsExpiring))
                : dispatch(toggleBenefitsModal(true));
        };

        const startRenewal = () => {
            navigate(routeString(RouteSection.AccountSettings, RouteSection.EncoreInterest));
        };

        const addVehicle = () => {
            navigate(routeString(RouteSection.Vehicle, RouteSection.Add));
        };

        const refresh = () => window.location.reload();

        switch (membershipStatus) {
            case UserEncoreStatus.EXPIRED:
            case UserEncoreStatus.PAYMENT_UPGRADE_EXPIRED:
            case UserEncoreStatus.PAYMENT_RENEWAL_EXPIRED:
                return startRenewal;
            case UserEncoreStatus.NONE:
                return addVehicle;
            case UserEncoreStatus.EXPIRING_SOON:
                return readMore;
            case UserEncoreStatus.SERVICE_DOWN:
                return refresh;
            default:
                return undefined;
        }
    }, [dispatch, history, membershipStatus]);
};

export const useBannerTokens = (bannerContent: UserEncoreStatus) => {
    const encoreTier = (useSelector(encoreTierSelector) || UserEncoreTiers.NONE) as UserEncoreTiers;
    const { expiryDate, membershipStatus } = useSelector(dashboardBannerStatesSelector);
    if (!expiryDate) {
        return undefined;
    }

    const expiryDateObject = parse(expiryDate, "dd/MM/yyyy", new Date());
    const formattedExpiryDate = format(parse(expiryDate, "dd/MM/yyyy", new Date()), "dd MMMM yyyy");
    const monthsTilExpiry = differenceInMonths(expiryDateObject, new Date()) + 1;
    const suffix = monthsTilExpiry > 1 ? "s" : "";
    let bannerText = "purchase";
    if ([UserEncoreTiers.LEGACY, UserEncoreTiers.BASIC].includes(encoreTier)) {
        switch (membershipStatus) {
            case UserEncoreStatus.ACTIVE:
            case UserEncoreStatus.EXPIRING_SOON:
                bannerText = "upgrade";
                break;
            case UserEncoreStatus.EXPIRED:
                bannerText = "purchase";
                break;
            default:
                bannerText = "purchase";
        }
    }
    const tokens: BannerTokensProps = {
        [UserEncoreStatus.EXPIRING_SOON]: {
            headerToken: { encoreTier },
            // if expiry date is this month then monthsTillExpiry will be 0, need to set to 1 for readability
            token: { monthsTilExpiry, formattedExpiryDate, suffix },
        },
        [UserEncoreStatus.EXPIRED]: {
            headerToken: { encoreTier },
        },
        [UserEncoreStatus.RENEWAL_SUCCESSFUL]: {
            token: { formattedExpiryDate },
        },
        [UserEncoreStatus.PAYMENT_UPGRADE_EXPIRED]: {
            token: { bannerText },
        },
        [UserEncoreStatus.PAYMENT_RENEWAL_EXPIRED]: {
            token: { bannerText },
        },
        [UserEncoreStatus.PAYMENT_UPGRADE]: {
            token: { bannerText },
        },
    };
    return tokens[bannerContent] || undefined;
};

export const getExtraBannerStatusForPayment = (
    isExpired: boolean,
    encoreTier: UserEncoreTiers,
    packagePaymentStatus: EncorePackagePaymentStatus
): UserEncoreStatus => {
    if (packagePaymentStatus === EncorePackagePaymentStatus.PaymentPending)
        return isExpired ? UserEncoreStatus.START_RENEWAL : UserEncoreStatus.START_UPGRADE;
    if (packagePaymentStatus === EncorePackagePaymentStatus.PaylinkExpired) {
        switch (encoreTier) {
            case UserEncoreTiers.BASIC:
            case UserEncoreTiers.LEGACY:
                return isExpired ? UserEncoreStatus.PAYMENT_UPGRADE_EXPIRED : UserEncoreStatus.PAYMENT_UPGRADE;
            case UserEncoreTiers.PLATINUM:
            case UserEncoreTiers.ELEVATE:
                return UserEncoreStatus.PAYMENT_RENEWAL_EXPIRED;
        }
    }
    return UserEncoreStatus.NONE;
};

export const mapEncorePackageToBannerContent = (
    encoreTier: UserEncoreTiers,
    vehicle: VerifiedVehicle | undefined,
    vehicleStatus: LoadStatus
): BannerStates | undefined => {
    // no vehicle flow
    if (vehicle === undefined || vehicleStatus === LoadStatus.Failure)
        return {
            bannerState: {
                status: UserEncoreStatus.NONE,
                showBanner: true,
            },
        };
    // api error
    if (vehicle.encorePackage === null) {
        return {
            bannerState: {
                status: UserEncoreStatus.SERVICE_DOWN,
                showBanner: true,
            },
        };
    }
    if (vehicleStatus !== LoadStatus.Success) return undefined;
    // If vehicle data is not returned yet, we don't setup any banner

    const { packageStartDate, packageExpiryDate, packagePaymentStatus, packagePurchasePaylink } = vehicle.encorePackage;
    const currentDate = new Date();
    const startDate = parse(packageStartDate, "dd/MM/yyyy", new Date());
    const expiryDate = packageExpiryDate ? parse(packageExpiryDate, "dd/MM/yyyy", new Date()) : null;
    const gapInMonths = expiryDate ? differenceInMonths(expiryDate, currentDate) : null;
    const isExpired = isDateExpired(packageExpiryDate);
    const isActive = gapInMonths && gapInMonths >= EXPIRING_DURATION;
    const shouldHideSuccessfulBanner =
        packagePaymentStatus === EncorePackagePaymentStatus.PaymentCompleted &&
        differenceInDays(currentDate, startDate) > DAYS_TO_HIDE_BANNER;
    let membershipStatus = undefined;

    if (isActive) membershipStatus = UserEncoreStatus.ACTIVE;
    else if (isExpired) membershipStatus = UserEncoreStatus.EXPIRED;
    else membershipStatus = UserEncoreStatus.EXPIRING_SOON;

    if (
        (packagePaymentStatus &&
            [EncorePackagePaymentStatus.Eligible, EncorePackagePaymentStatus.NotEligible].includes(
                packagePaymentStatus
            )) ||
        shouldHideSuccessfulBanner
    ) {
        return {
            bannerState: { status: membershipStatus, packageExpiryDate, packagePurchasePaylink, showBanner: !isActive },
        };
    }

    if (!encoreTier) return undefined;

    switch (packagePaymentStatus) {
        case EncorePackagePaymentStatus.PaymentPending:
            return {
                // need to implicitly set banner states for membership card
                bannerState: {
                    status: isExpired ? UserEncoreStatus.EXPIRED : UserEncoreStatus.START_UPGRADE,
                    packageExpiryDate,
                    packagePurchasePaylink,
                    disableCta: true,
                    showBanner: isExpired,
                },
                extraBannerState: {
                    status: getExtraBannerStatusForPayment(isExpired, encoreTier, packagePaymentStatus),
                },
            };
        case EncorePackagePaymentStatus.PaylinkExpired:
            return {
                bannerState: {
                    status: membershipStatus,
                    packageExpiryDate,
                    packagePurchasePaylink,
                    hideCta: true,
                    showBanner: isExpired,
                },
                ...{
                    extraBannerState: {
                        status:
                            encoreTier && [UserEncoreTiers.BASIC, UserEncoreTiers.LEGACY].includes(encoreTier)
                                ? UserEncoreStatus.PAYMENT_UPGRADE_EXPIRED
                                : UserEncoreStatus.PAYMENT_RENEWAL_EXPIRED,
                    },
                },
            };
        case EncorePackagePaymentStatus.PaymentFailed:
            return {
                bannerState: { status: membershipStatus, packageExpiryDate, showBanner: false },
                ...{
                    extraBannerState: {
                        status: UserEncoreStatus.PAYMENT_ERROR,
                    },
                },
            };
        case EncorePackagePaymentStatus.PaymentCompleted:
            return {
                bannerState: {
                    status: UserEncoreStatus.RENEWAL_SUCCESSFUL,
                    packageExpiryDate,
                    showBanner: !shouldHideSuccessfulBanner,
                },
            };
    }

    return undefined;
};

export const isDateExpired = (packageExpiryDate?: string) => {
    if (!packageExpiryDate) return true;
    const expiryDate = parse(packageExpiryDate, "dd/MM/yyyy", new Date());
    const currentDate = new Date();

    return differenceInDays(expiryDate, currentDate) <= 0;
};
