import * as React from "react";
import { useSelector } from "react-redux";
import { primaryNavigationDataSelector } from "ReduxSlices/general";
import {
    PrimaryNav,
    PrimaryNavContainer,
    PrimaryNavData,
    PrimaryNavRawData,
    convertToPrimaryNavData,
    GlobalStylesScope,
    AuthenticationContextProvider,
    darkTheme,
} from "@tmca/lexus-kit/css-in-js";
import { RouteSection, routeString } from "Helpers/routes";
import { useSettingsPromise } from "Hooks/usePromiseState";
import { useNavigate } from "react-router-dom";

// TODO hardcode ENCORE_URL for now until authenticationUrl is refactored
const ENCORE_URL = typeof window !== "undefined" ? location.protocol + "//" + location.host : "";

const PrimaryNavigationSC10: React.FC = () => {
    //#region hooks
    const generalsettings = useSettingsPromise(settings => settings.general);
    const data = useSelector(primaryNavigationDataSelector);
    //#endregion

    let dataConverted: PrimaryNavData | undefined;
    const navigate = useNavigate();
    const AUTHENTICATION_DATA = {
        config: {
            loginFormUrl: `${ENCORE_URL}/signin?type=iframe`,
            logoutUrl: `${ENCORE_URL}/silentsignout`,
            refreshUrl: `${ENCORE_URL}/refresh`,
            isAuthenticationHost: true,
        },
        onLogout: () => {
            navigate(routeString(RouteSection.SignOut));
        },
    };
    const mainsiteBaseUrl = generalsettings?.mainsiteBaseUrl
        ? generalsettings?.mainsiteBaseUrl
        : generalsettings?.lexusBrandBaseUrl;

    if (data && "details" in data) {
        // TODO: JSON.parse(JSON.stringify(data)) required as we do mutate the object structure in LKit
        // we need to fix it. https://toyotaau.atlassian.net/browse/LEXNW-509
        const dataRaw: PrimaryNavRawData = JSON.parse(JSON.stringify(data));
        dataConverted = convertToPrimaryNavData(dataRaw);
    }

    if (!dataConverted) {
        return null;
    }

    if (typeof dataConverted.searchSettings === "object") {
        // TODO: We may need to add this to config later to enable/disable search
        // https://toyotaau.atlassian.net/browse/LEXUS-6594
        dataConverted.searchSettings.showSearch = false;
    }

    return (
        <GlobalStylesScope themeDefinition={darkTheme}>
            <PrimaryNavContainer
                initialIsFixedAtTop={false}
                initialIsSolidBackground={true}
                initialIsSolidContainer={true}
            >
                <AuthenticationContextProvider {...AUTHENTICATION_DATA}>
                    <PrimaryNav mainMenuLogoHref={mainsiteBaseUrl} {...dataConverted} />
                </AuthenticationContextProvider>
            </PrimaryNavContainer>
        </GlobalStylesScope>
    );
};

export default PrimaryNavigationSC10;
