import type { AvailableIcons } from "@/components/SVGIcon/static/SVGSelector";
import type { LinkData, ImageData } from "@/types/graphqlResponse";

export const MODAL_TRANSITION_DURATION_MS = 300;
export const MODAL_CONTENT_TRANSITION_DURATION_MS = 300;
export const MODAL_SCROLL_OFFSET = 10;

export const MainMenuType = {
  MAIN_MENU: "MainMenu",
  STATIC_LINKS: "StaticLinks",
  SEARCH_LINKS: "SearchQuickLinks",
} as const;

export const ModalDataType = {
  MOBILE_MENU: "mobile-menu",
  SEARCH_MENU: "search-menu",
  DEALER: "DealerMenu",
  MODELS: "ModelsMenu",
  GENERIC: "C__MainMenuEntryLevel1",
  ENCORE: "EncoreMenu",
} as const;

export const MenuItemType = {
  WITHCHILDREN: "MainMenuEntryLevel2",
  LEAF: "NavigationLeafEntry",
} as const;

export const NavTagGroup = {
  BODY: "body",
  FUEL: "fuel",
} as const;

export type MenuTagData = {
  name: string;
  group: typeof NavTagGroup;
};

export type SearchSettings = {
  showSearch?: boolean;
  rfkId?: string;
  resultsBlockAriaLabel?: string;
  searchFieldPlaceholder?: string;
  searchAllLinkText?: string;
  maxPreviewResults?: number;
};

export type PrimaryNavData = {
  staticLinks?: StaticLinksItem[];
  searchLinks?: SearchQuickLinksItem[];
  mainMenu?: MainMenuItem;
  logoLarge?: ImageData;
  logoSmall?: ImageData;
  searchSettings?: SearchSettings;
};

export type MainMenuItem = {
  menuType: typeof MainMenuType.MAIN_MENU;

  /**
   * @deprecated Use `logoLarge` on PrimaryNavData instead
   */
  logo?: ImageData;

  /**
   * @deprecated Use `logoSmall` on PrimaryNavData instead
   */
  logoMobile?: ImageData;
  menuTextMobile?: string;
  closeText?: string;
  menus: Menu[];
};

export type StaticLinksItem = {
  menuType: typeof MainMenuType.STATIC_LINKS;
  usedByEncore: boolean;
  links: MenuItemWithIconData[];
};

export type SearchQuickLinksItem = {
  menuType: typeof MainMenuType.SEARCH_LINKS;
  displayName: string | undefined;
  showWithResults: boolean;
  showWithNoResults: boolean;
  showWithBlankInput: boolean;
  links: MenuItemData[];
};

export type DealerData = {
  label: string;
  map: string; // TODO: Update to object { lat, lng } etc...
};

export type MenuItemData = {
  link?: LinkData;
  onClick?: () => void;
};

export type EncoreAdvert = {
  title: string;
  description: string;
  googlePlayImageUrl?: string;
  googlePlayImageAlt?: string;
  googlePlayLinkUrl?: string;
  googlePlayLinkTarget?: string;
  appStoreImageUrl?: string;
  appStoreImageAlt?: string;
  appStoreLinkUrl?: string;
  appStoreLinkTarget?: string;
  heroImageUrl?: string;
  heroImageAlt?: string;
  ctaUrl?: string;
  ctaLabel?: string;
  ctaTarget?: string;
};

export type MenuItemWithChildren = {
  type: typeof MenuItemType.WITHCHILDREN;
  name: string;
  menuItems?: MenuItemData[];
};

export type MenuItemWithoutChildren = MenuItemData & {
  type: typeof MenuItemType.LEAF;
};

export type MenuItemWithIconData = MenuItemData & {
  icon?: AvailableIcons | string;
};

export type BodyTypeData = {
  displayName: string;
  models: ModelData[];
};

export type ModelData = {
  displayName: string;
  bodyType: string;
  modelName?: string;
  fuelTypes: string[];
  menuImage: ImageData;
  menuHoverImage: ImageData;
  contentPath?: string;
  hideFromMenu?: boolean;
};

export type BaseMenu = {
  menuId: string;
  label: string;
  mobileLabel?: string;
};

export type MobileMenu = BaseMenu & {
  type: typeof ModalDataType.MOBILE_MENU;
};

export type SearchMenu = BaseMenu & {
  type: typeof ModalDataType.SEARCH_MENU;
};

export type ModelsMenu = BaseMenu & {
  type: typeof ModalDataType.MODELS;
  filterButtonText?: string;
  clearCtaText?: string;
  showCtaText?: string;
  scrollIndicatorDescription?: string;
  availableBodyTypes: BodyTypeData[];
};

export type GenericMenu = BaseMenu & {
  type: typeof ModalDataType.GENERIC;
  menuItems: MenuItemTyped[];
};

export type EncoreMenu = BaseMenu & {
  logoutLabel: string;
  type: typeof ModalDataType.ENCORE;
  menuItems: MenuItemData[];
  encoreAdvert?: EncoreAdvert;
};

export type DealerMenu = BaseMenu & {
  type: typeof ModalDataType.DEALER;
  menuItems: MenuItemData[];
};

export type Menu = ModelsMenu | GenericMenu | EncoreMenu | DealerMenu | MobileMenu | SearchMenu;

export type MenuItemTyped = MenuItemWithChildren | MenuItemWithoutChildren;

export type FilterTypes = {
  baseType: string;
  supportTypes: string[];
  models: string[];
  isSelected: boolean;
  isDisabled: boolean;
};

export interface PrimaryNavSlots {
  searchPopover: React.ReactNode | undefined;
  searchMenu: React.ReactNode | undefined;
  searchMenuTrigger: React.ReactNode | undefined;
}

export const isGenericMenu = (menu: Menu): menu is GenericMenu =>
  menu.type === ModalDataType.GENERIC;

export const isMenuItemWithChildren = (menuItem: MenuItemTyped): menuItem is MenuItemWithChildren =>
  menuItem.type === MenuItemType.WITHCHILDREN;
