import { ShadowProps } from "./Shadow";

export const getCustomCssProps = ({ variant, overlay }: ShadowProps): React.CSSProperties => {
  return {
    ["--lk-private-shadow-variant"]: (() => {
      switch (variant) {
        case "darker":
          return "var(--ld-c-shadow-darker)";
        default:
          return "var(--ld-c-shadow-default)";
      }
    })(),
    ["--lk-private-shadow-overlay"]:
      typeof overlay === "string" && ["top", "bottom", "left", "right"].includes(overlay)
        ? overlay
        : undefined,
  };
};
