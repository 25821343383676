import cn from "classnames";
import { Children, isValidElement, useRef, useState } from "react";
import {
  withGroupedChildren,
  type WithGroupedChildrenComponent,
  type GroupedChildrenProps,
} from "react-grouped-children";

import { Oriented } from "@/components/Oriented/Oriented";
import { TooltipContextProvider } from "@/contexts/TooltipContext";

import styles from "./DynamicMedia.module.scss";
import { TooltipLocation } from "./DynamicMedia.utils";
import { getCustomCssProps } from "./helpers";
import { VideoOrImage, VideoOrImageProps } from "./VideoOrImage";
import { Layers } from "../Layers/Layers";
import { Shadow } from "../Shadow/Shadow";
import { ContainerCustomProps } from "../types";

type VideoTypes = "Landscape" | "Portrait";
type VideoProps = { [P in `video${VideoTypes}`]?: VideoOrImageProps["videoInformation"] } & {
  tooltipLocation?: TooltipLocation;
};

export type DynamicMediaProps = React.PropsWithChildren &
  VideoProps &
  ContainerCustomProps & {
    /**
     * Use to render all variants of component. Helpful in CMS editing mode
     */
    showAll?: boolean;
    showContentGradient?: boolean;

    /**
     * Whether video is paused or not
     */
    isVideoPaused?: boolean;
  };

const DynamicMediaInternal = ({
  content,
  contentFooter,
  imageLandscape,
  imagePortrait,
  videoLandscape,
  videoPortrait,
  videoLandscapeDisclaimer,
  videoPortraitDisclaimer,
  imageLandscapeDisclaimer,
  imagePortraitDisclaimer,
  showContentGradient,
  showAll,
  isVideoPaused,
  children,
  ...rest
}: GroupedChildrenProps<typeof childrenSpec> & DynamicMediaProps) => {
  const [tooltipContent, setTooltipContent] = useState<React.ReactNode | undefined>(undefined);
  const containerRef = useRef<HTMLDivElement>(null);

  const imageLandscapeSingle = Children.toArray(imageLandscape).filter(isValidElement)[0];
  const imagePortraitSingle = Children.toArray(imagePortrait).filter(isValidElement)[0];
  const hasPortrait = !!(imagePortraitSingle || videoPortrait);
  const hasLandscape = !!(imageLandscapeSingle || videoLandscape);

  if (!hasPortrait && !hasLandscape) {
    return null;
  }

  const hasOnlyOne = hasPortrait ? !hasLandscape : hasLandscape;
  const minHeightCSSProperties = getCustomCssProps(rest);

  return (
    <TooltipContextProvider setTooltip={setTooltipContent} tooltip={tooltipContent}>
      <div
        className={cn(styles.dynamicMedia, {
          [styles.aspect]: typeof rest.aspectRatio === "number" && rest.aspectRatio > 0,
        })}
        style={minHeightCSSProperties}
      >
        <div ref={containerRef} className={styles.container}>
          <Layers className={styles.fullSize}>
            <div>
              <Oriented defaultOrientation="portrait" showAll={hasOnlyOne || showAll}>
                {hasPortrait && (
                  <Oriented.Portrait>
                    <VideoOrImage
                      className={styles.videoOrImage}
                      image={imagePortraitSingle}
                      videoInformation={videoPortrait}
                      videoDisclaimer={videoPortraitDisclaimer}
                      imageDisclaimer={imagePortraitDisclaimer}
                      isVideoPaused={isVideoPaused}
                    />
                  </Oriented.Portrait>
                )}
                {hasLandscape && (
                  <Oriented.Landscape>
                    <VideoOrImage
                      className={styles.videoOrImage}
                      image={imageLandscapeSingle}
                      videoInformation={videoLandscape}
                      videoDisclaimer={videoLandscapeDisclaimer}
                      imageDisclaimer={imageLandscapeDisclaimer}
                      isVideoPaused={isVideoPaused}
                    />
                  </Oriented.Landscape>
                )}
              </Oriented>
            </div>

            {showContentGradient && <Shadow variant="darker" />}
            <div className={cn(styles.contentInner, styles.tooltipContainer)}>
              {content}
              {children}
            </div>
          </Layers>

          {contentFooter && <div className={styles.footer}>{contentFooter}</div>}
        </div>
      </div>
    </TooltipContextProvider>
  );
};

const childrenSpec = {
  ImageLandscape: null,
  ImagePortrait: null,
  ImageLandscapeDisclaimer: null,
  ImagePortraitDisclaimer: null,
  VideoLandscapeDisclaimer: null,
  VideoPortraitDisclaimer: null,
  Content: null,
  ContentFooter: null,
} as const;

const Component: WithGroupedChildrenComponent<typeof childrenSpec, DynamicMediaProps> =
  withGroupedChildren({ childrenSpec })(DynamicMediaInternal);

/**
 * A layout which is used on the homepage of Lexus.com.au to display images or videos which is full width and full visible height.
 */
export const DynamicMedia = Component;
