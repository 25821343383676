import * as React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { useSelector } from "react-redux";
import { primaryNavigationDataSelector } from "ReduxSlices/general";

const PrimaryNavigation: React.FC = () => {
    //#region hooks
    const data = useSelector(primaryNavigationDataSelector);
    //#endregion

    return data && !("details" in data) ? (
        <Placeholder name="lexus-project-mainsite-navigation" rendering={data} />
    ) : null;
};

export default PrimaryNavigation;
