import Spinner from "Components/Spinner/Spinner";
import { RouteSection, routeString, toastUrlFromPath } from "Helpers/routes";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AuthSection, authSectionSelector, guestDetailsSelector } from "ReduxSlices/user";
import { defaultLoggedOutRoute } from "../../pages/Account/accountScreens";
import { vehiclesFetchStatusSelector } from "ReduxSlices/vehicle";
import { LoadStatus } from "Types/loadStatus";
import { isStandAlone } from "Helpers/general";

const AuthenticationGuard: React.FC<React.PropsWithChildren> = ({ children }) => {
    const navigate = useNavigate();
    const authSection = useSelector(authSectionSelector);
    const guestDetails = useSelector(guestDetailsSelector);
    const vehicleStatus = useSelector(vehiclesFetchStatusSelector);

    const [guestStatus, setGuestStatus] = React.useState(false);
    const [authVerified, setAuthVerified] = React.useState(false);

    const loggedOutRedirect = isStandAlone() ? routeString(RouteSection.Welcome) : defaultLoggedOutRoute;

    React.useEffect(() => {
        if (authSection === AuthSection.App) {
            setAuthVerified(true);
        }
        if (authSection === AuthSection.Account) {
            navigate(loggedOutRedirect);
        }
        if (authSection === AuthSection.BFFError) {
            navigate(toastUrlFromPath(RouteSection.Error, RouteSection.Notify));
        }
        if (authSection === AuthSection.SalesforceError) {
            navigate(toastUrlFromPath(RouteSection.GuestRegistrationError, RouteSection.Notify));
        }
        if (authSection === AuthSection.Error) {
            navigate(toastUrlFromPath(RouteSection.Error, RouteSection.Notify));
        }
    }, [authSection]);

    React.useEffect(() => {
        if (guestDetails?.accountId) {
            setGuestStatus(true);
        }
    }, [guestDetails]);

    return !guestStatus || !authVerified || vehicleStatus === LoadStatus.InProgress ? <Spinner /> : <>{children}</>;
};
export default AuthenticationGuard;
