import * as React from "react";
import { BreakpointContext } from "../context/BreakpointContext";

export const createSwitchMobileDesktopComponent: (mobile: React.ReactNode, desktop: React.ReactNode) => React.FC =
    (mobile, desktop) => () => {
        const isMobile = React.useContext(BreakpointContext);
        return isMobile ? <>{mobile}</> : <>{desktop}</>;
    };

const mqStandAlone = "(display-mode: standalone)";
export const getAppMode = (): "browser" | "standalone" => {
    let appMode: "browser" | "standalone" = "browser";
    if (
        window.matchMedia(mqStandAlone).matches ||
        ("standalone" in window.navigator && !!window.navigator.standalone) ||
        document.referrer.includes("android-app://")
    ) {
        appMode = "standalone";
    }
    return appMode;
};

// Return true if running as app or PWA
export const isStandAlone = (): boolean => {
    return getAppMode() === "standalone";
};
