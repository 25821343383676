import cn from "classnames";

import { ThemeSurfaceShade, ThemeSurfaceType } from "@/theming/themingTypes";
import { kebabToCamelCase, kebabToPascalCase } from "@/utils/stringUtils";

import styles from "./Surface.module.scss";

type HeadlessSurfaceProps = {
  className: string;
};

const TYPES_WITH_SHADES = ["canvas", "elevation-inset", "elevation-raised"] as const;

type TypesWithShades = (typeof TYPES_WITH_SHADES)[number];

const isTypeWithShade = (type: ThemeSurfaceType): type is TypesWithShades =>
  !!TYPES_WITH_SHADES.find((t) => t === type);

const getShadedClassName = (type: TypesWithShades, shade: ThemeSurfaceShade) =>
  `${kebabToCamelCase(type)}${kebabToPascalCase(shade)}` as const;

/**
 * A Headless component that provides solid surface to other elements and sets default foreground color.
 * @param type - Type defines type of surface. Default: "canvas".
 * @param shade - Shade defines shade of background color. Default: "default".
 * @returns A class name that sets surface background and fore color
 *
 * ## Usage
 * ```tsx
 * const surfaceProps = getSurfaceProps();
 * // OR
 * // const surfaceProps = getSurfaceProps("elevation-inset", "lighter");
 *
 * return <div {...surfaceProps}>Content</div>;
 * ```
 *
 * ## Note
 * `shade` is only applicable for `canvas`, `elevation-inset` and `elevation-raised`
 * types. Use `default` or `undefined` for other types.
 *
 * ## Tips
 *  - You can use `--lk-surface-color` CSS custom property in your components. This is helpful if you want to match
 *  the surface color in a gradient background.
 */
export const getSurfaceProps = (
  type: ThemeSurfaceType = "canvas",
  shade: ThemeSurfaceShade = "default",
  highlightOnHover = false
): HeadlessSurfaceProps => ({
  className: cn(
    styles.surface,
    styles[isTypeWithShade(type) ? getShadedClassName(type, shade) : type],
    { [styles.highlightOnHover]: highlightOnHover }
  ),
});

/**
 * @deprecated Use `getSurfaceProps("canvas", canvas)` instead.
 *
 * A Headless component that provides canvas to other elements.
 * @param canvas - Canvas defines shade of background color. Default: "default".
 * @returns A class name that has canvas background color
 *
 * ## Usage
 * ```tsx
 * const canvasProps = getCanvasProps();
 *
 * return <div {...canvasProps}>Content</div>;
 * ```
 *
 * ## Tips
 *  - You can use `--lk-canvas-color` CSS custom property in your components. This is helpful if you want to match
 *  the canvas color in a gradient background.
 */
export const getCanvasProps = (canvas: ThemeSurfaceShade = "default"): HeadlessSurfaceProps =>
  getSurfaceProps("canvas", canvas);
